/* You can add global styles to this file, and also import other style files */

:root {
    --gMapHeight: 100%;
    --gMapWidth: 100%;
    --toolbarHeight: 5vh;
    --white: #ffffff;
}

html, body { height: 100%; }
body {
    height: 100%;
    background: #f1f1f1 !important;
}
body { background: #f1f1f1 !important; margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; height: 100vh !important; width: 100vw !important; }

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: #B3AFB3;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #3F51B5;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

.map-container {
    margin: unset;
    height: var(--gMapHeight) !important;
    width: var(--gMapWidth) !important;
}




section { padding: 16px; }
.filterRow{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.filterRow > button {
    margin-left: 8px;
}
.navColumn{
    display: flex;
    flex-wrap: nowrap;
    height: 98%;
    flex-direction: column;
}
/* .child-height-auto {
    align-self: start;
    width: 100%;
} */
.child-height-strech {
    align-self: stretch;
    width: 100%;
    flex-grow: 1;
}
 table {
    width: 100%;
}
/*
td, th {
    width: 25%;
} */
.roundedTable {
    border-radius: 12px 12px 12px 12px;
    overflow: hidden !important;
}
.mat-stepper-horizontal-line {
    border-top-width: 1px;
    border-top-style: solid;
    flex: auto;
    height: 0;
    margin: 0 -16px;
    min-width: 32px;
    max-width: 32px;
}

.mat-expansion-panel-header.mat-expanded{
    height: 42px;
  }

.mat-expansion-panel-body{
padding: 0 12px 0px !important;
}


.mat-horizontal-stepper-header {
    display: flex !important;
    height: 38px !important;
    overflow: hidden ;
    align-items: center !important;
    padding: 0 20px !important;
}
.mat-dialog-container{
    padding: 12px !important;
}

.glass-bg {
    background: rgba(0,0,0,0.55);
    backdrop-filter: saturate(180%) blur(10px);
  }

  .markerLable {
    background-color: darkslategrey;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid white;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.successBadge{
    padding: 8px;
    background: #28a745;
    color: white;
    border: 2px solid ghostwhite;
    border-radius: 8px;
}
.warnBadge{
    padding: 8px;
    background: #f44336;
    color: white;
    border: 2px solid ghostwhite;
    border-radius: 8px;
}
.infoBadge{
    padding: 8px;
    background: #3f51b5;
    color: white;
    border: 2px solid ghostwhite;
    border-radius: 8px;
}
.chocBadge{
    padding: 8px;
    background: #795548;
    color: white;
    border: 2px solid ghostwhite;
    border-radius: 8px;
}

.mat-choc {
    color: #fff !important;
    background-color: #795548 !important;
  }
.mat-iconDone {
    background-color: #fff !important;
    color: #28a745 !important;
  }
.mat-iconChoc {
    background-color: #fff !important;
    color: #795548 !important;
  }
.mat-pending {
    background-color: #fff !important;
    color: #708090c4 !important;
  }
.mat-iconWarn {
    background-color: #fff !important;
    color: #f44336 !important;
  }
.mat-male {
    background-color: #fff !important;
    color: royalblue !important;
  }
.mat-female {
    background-color: #fff !important;
    color: hotpink !important;
  }
.mat-trangender {
    background-color: #fff !important;
    color: royalblue !important;
  }
.mat-preferNtS {
    background-color: #fff !important;
    color: cadetblue !important;
  }